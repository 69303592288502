import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, ReplaySubject, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class MessageService {
    private subject = new Subject();
    private subjectbis = new Subject();
    private writeMessage = new Subject();
    private openWriteMessage = new Subject();
    private closeWriteMessage = new Subject();
    private translate = new ReplaySubject();
    private libelleproject = new ReplaySubject();
    private envoyerObs = new Subject();
    private guide = new Subject();
    private projectChosen = new Subject();
    private showCreateProjectTutoIsFalse = new Subject();
    private showProjectTutoIsFalse = new Subject();
    private messageIsSend = new Subject();
    private showCreateProjectTuto = true;
    private showProjectTuto = true;
    private showProjectTutobis = true;
    private showNotifTuto = true;
    private reloadNotifAction = new Subject();
    private reloadPropostionNotif = new Subject();
    private reloadBoardNotif = new Subject();
    private reloadActionNotif = new Subject();
    private project_list = [];


    obsReloadActionNotif(): Observable<any> {
        return this.reloadActionNotif.asObservable();
    }

    nextReloadActionNotif() {
        this.reloadActionNotif.next();
    }

    obsReloadBoardNotif(): Observable<any> {
        return this.reloadBoardNotif.asObservable();
    }

    nextReloadBoardNotif() {
        this.reloadBoardNotif.next();
    }

    nextReloadNotifAction() {
        this.reloadNotifAction.next();
    }

    obsReloadNotifAction(): Observable<any> {
        return this.reloadNotifAction.asObservable();
    }

    nextReloadPropostionNotif() {
        this.reloadPropostionNotif.next();
    }

    obsReloadPropostionNotif(): Observable<any> {
        return this.reloadPropostionNotif.asObservable();
    }

    sendMessage(message: string) {
        this.subject.next();
    }

    messageUpdated() {
        this.subject.next();
    }

    needRefreshFiltre() {
        this.subjectbis.next();
    }

    messageRefreshConv() {
        this.envoyerObs.next();
    }

    getMessageRefreshConv(): Observable<any> {
        return this.envoyerObs.asObservable();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    getRefreshFiltre(): Observable<any> {
        return this.subjectbis.asObservable();
    }

    getWriteMessage(): Observable<any> {
        return this.writeMessage.asObservable();
    }

    setWriteMessage(id) {
        this.writeMessage.next(id);
    }

    getWriteMessageFace(): Observable<any> {
        return this.openWriteMessage.asObservable();
    }

    openWriteMessageFace(openMode) {
       this.openWriteMessage.next(openMode);
    }

    setLoseWriteMessageFace(id){
        this.openWriteMessage.next(id);
    }

    getCloseWriteMessage(openMode){
       this.closeWriteMessage.next(openMode);
    }

    setCloseWriteMessage(): Observable<any> {
       return this.closeWriteMessage.asObservable();
    }

    setTranslate(id) {
        this.translate.next(id);
    }

    setLibelleProject(project) {
        console.log("setted libelle");
        this.libelleproject.next(project);
    }

    getTranslate(): Observable<any> {
        return this.translate.asObservable();
    }

    getLibelleProject(): Observable<any> {
        return this.libelleproject.asObservable();
    }

    getGuide(): Observable<any> {
        return this.guide.asObservable();
    }

    setGuide(id) {
        this.guide.next(id);
    }

    setProjectChosen(id) {
        this.projectChosen.next(id);
    }

    getProjectChosen() {
        return this.projectChosen.asObservable();
    }

   setShowCreateProjectTuto(value) {
        this.showCreateProjectTuto = value;
    }

    getShowCreateProjectTuto() {
        return this.showCreateProjectTuto;
    }

    setShowProjectTuto(value) {
        this.showProjectTuto = value;
    }

    getShowProjectTuto() {
        return this.showProjectTuto;
    }

    setShowProjectTutobis(value) {
        this.showProjectTutobis = value;
    }

    getShowProjectTutobis() {
        return this.showProjectTutobis;
    }

    setShowNotifTuto(value) {
        this.showNotifTuto = value;
    }

    getShowNotifTuto() {
        return this.showNotifTuto;
    }

    getShowCreateProjectTutoIsFalse(): Observable<any> {
        return this.showCreateProjectTutoIsFalse.asObservable();
    }

    setShowCreateProjectTutoIsFalse() {
        this.showCreateProjectTutoIsFalse.next();
    }

    getShowProjectTutoIsFalse(): Observable<any> {
        return this.showProjectTutoIsFalse.asObservable();
    }

    setShowProjectTutoIsFalse() {
        this.showProjectTutoIsFalse.next();
    }

     setMessageIsSend() {
        this.messageIsSend.next();
    }

    getMessageIsSend(): Observable<any> {
        return this.messageIsSend.asObservable();
    }

    getListProject(){
        return this.project_list;
    }

    setListProject(projectList){
        this.project_list = projectList;
    }

}