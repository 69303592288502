import { Component, OnInit, ViewChild, ElementRef, Inject, ChangeDetectorRef, Input, QueryList, EventEmitter, Output } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ErrorStateMatcher} from '@angular/material/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm} from '@angular/forms';


export interface Contacts {
	'@id': string;
	'@type': string;
	id: string;
	email: string;
  givenName: string;
  familyName: string;
	isProject: boolean;
	isAdmin: boolean;
	dateModified: string;
	user:any;
  invitation: boolean;
  value:string;
}

@Component({
  selector: 'app-company-member',
  templateUrl: './company-member.component.html',
  styleUrls: ['./company-member.component.scss']
})
export class CompanyMemberComponent implements OnInit {

	contacts: Array<Contacts> = [];
  searchContact: any;
	currentUser = null;
  confirm_mode = false;
  array_user_add = [];
  array_exised_user = [];
  admin = false;
  userEmail = '';
  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;


	constructor(private authService: AuthService, private router: Router,  public  dialog: MatDialog) { 
  }

	ngOnInit() {
    this.confirm_mode = false;
		this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.admin = this.currentUser.roles.includes('ROLE_MANAGER');
    this.userEmail = this.currentUser.email;
    //this.currentUser.company = "bd5bcc26-9734-4e52-8074-01e827715298";
    if (this.currentUser.compId) {
      //console.log("compay id "+this.currentUser.compId);
		  this.loadContact();
    }
	}

	loadContact() {
		this.authService.getCompagnyMember(this.currentUser['compId']).subscribe(res => {
		  this.contacts = res['hydra:member'];
      this.loadContactNotRegister();
		},
	  	err => {
	      const error = err.error;
	      const errorS = error.message;
	      const errorD = error.detail;
	      const errorH = error['hydra:description'];
	      if (errorS === 'Bad credentials') {
	         alert(errorS);
	      } else if (errorD === "L'utilisateur n'est pas encore activé. pour l'activer, vous devez cliquer sur le lien de confirmation du mail que vous avez reçu") {
	        alert(errorD);
	      } else if (errorH) {
	        alert(errorH);
	      }
	    });
	}

  loadContactNotRegister() {
    this.authService.getCompagnyMemberNotRegister().subscribe(res => {
      var contact_not_resgiter = res['hydra:member'];
      for (var i = 0; i < contact_not_resgiter.length; i++) {
        var contactUser = <Contacts>contact_not_resgiter[i];
        contactUser.invitation = true;
        contactUser.value = contact_not_resgiter[i].value;
        contactUser.familyName = contact_not_resgiter[i].value;
        contactUser.givenName = "";
        this.contacts.push(contactUser);
      }
    },
      err => {
        const error = err.error;
        const errorS = error.message;
        const errorD = error.detail;
        const errorH = error['hydra:description'];
        if (errorS === 'Bad credentials') {
           alert(errorS);
        } else if (errorD === "L'utilisateur n'est pas encore activé. pour l'activer, vous devez cliquer sur le lien de confirmation du mail que vous avez reçu") {
          alert(errorD);
        } else if (errorH) {
          alert(errorH);
        }
      });
  }

	goToProfile(profilId) {
		this.router.navigate(['/profile/' + profilId]);
	}

	getAvatarlUrl(avatarLink) {
		return environment.server_url + avatarLink;
	}

  deleteUserFromCompany(profilId) {
    this.authService.deleteUserCompany(profilId).subscribe(res => {
       this.loadContact();
    },
      err => {
        const error = err.error;
        const errorS = error.message;
        const errorD = error.detail;
        const errorH = error['hydra:description'];
        if (errorS === 'Bad credentials') {
           alert(errorS);
        } else if (errorD === "L'utilisateur n'est pas encore activé. pour l'activer, vous devez cliquer sur le lien de confirmation du mail que vous avez reçu") {
          alert(errorD);
        } else if (errorH) {
          alert(errorH);
        }
    });
  }

	fctAddMember() {
	    const dialogRef = this.dialog.open(addMemberCompany, {
	      width: '700px',
	      data: {idcompany: this.currentUser['compId'], companyUser: this.contacts}
	    });

	    dialogRef.afterClosed().subscribe(result => {
        console.log("rres "+result);
        if(result){
          var contactUser = <Contacts>{};
          contactUser.invitation = true;
          contactUser.value = result;
          contactUser.familyName = result;
          contactUser.givenName = "";
          this.contacts.push(contactUser);
        }
	      //console.log('The dialog was closed result add membre '+result);    
	    });
  }

  uploadFile(file) {

    this.authService.addMultipEmploye(file.item(0)).subscribe(
      res => {
        this.array_exised_user = res['user_existed'];
        this.array_user_add = res['user_invited'];
        this.confirm_mode = true;
        this.loadContact();
      },
      (error) => {
        alert('Erreur ! : ' + error);
        this.confirm_mode = false;
      }
    );

    this.fileInput.nativeElement.value = '';

  }

  goToMembers(){
    this.confirm_mode = false;
  }


   
}


/////////////////// add contact view dialog ///////////////////

/* Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'addMemberCompany-dialog-dialog',
  templateUrl: './addMemberCompany-dialog.html',
  styleUrls: ['./addMemberCompany-dialog.scss']
})
export class addMemberCompany {
 
  contacts = [];
  // userStatus : { name: string }[];
  showNewContactInput = false;
  searchContact: any;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  matcher = new MyErrorStateMatcher();
  addingMember = false;
  @ViewChild('emailInput', {static: true}) emailInput: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<addMemberCompany>,
    @Inject(MAT_DIALOG_DATA) public data: any,
     private authService: AuthService) {

      /*this.authService.getContacts().subscribe( dataContact => {
        this.contacts = dataContact['hydra:member'];
      });*/
      
      	
      	this.contacts = [];
      	//this.loadContact();

      /*this.authService.getTheProject(this.data.project.replace("/api/projects/", ""))
      .subscribe(res => {
        //console.log(res)
        var tmpArrayUser = res['userProject'];
        for (var i = 0; i < tmpArrayUser.length; i++) {
          if(tmpArrayUser[i].user){
            this.projectUsers.push(tmpArrayUser[i]);
          }
        }

        let userStatus = []; 
        for (let index = 0; index < this.projectUsers.length; index++) {
          if(this.projectUsers[index]['user'])
          userStatus[this.projectUsers[index]['user']['id']] = this.projectUsers[index]['status'];
        }
        this.userStatus = userStatus;
        //console.log(this.userStatus)
      }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
      });*/


      //console.log(' this.data.projectUsers ==> '+JSON.stringify(this.data.projectUsers));
      
  }


  loadContact(url?: string){
    url = url ? url : undefined;
    this.authService.getContacts(url).subscribe(res => {
      	this.contacts = this.contacts.concat(res['hydra:member']);
    	for (var i = 0; i < this.contacts.length; i++) {
    		for (let index = 0; index < this.data.companyUser.length; index++) {
    			if(this.contacts[i]['id'] == this.data.companyUser[index]['user']['id']){
    			 	this.contacts[i]['isMember'] = true;
		 		}
	 		}
    	}
		if(res['hydra:view']['hydra:next']){
			this.loadContact(res['hydra:view']['hydra:next'].replace("/api", ""));
		}
    }); 
  }


  newContact(){
    if (this.showNewContactInput == true)  
      this.showNewContactInput = false;
    else
      this.showNewContactInput = true;
  }



   fctAddcontactByMail(){

      // Process checkout data here
      //console.log('Your order has been submitted', customerData);

       // stop here if form is invalid
      if (this.emailFormControl.status == 'INVALID') {
        return;
      }

      var result = this.emailFormControl.value;

      this.authService.addUserCompany(this.data.idcompany, result, true).subscribe(res => {
         //console.log('retour ajout membre mail => '+JSON.stringify(res));
        this.searchContact = "";
        alert('Invitation envoyé'); 
        
        if(res['user'] && res['user']['id']){
          	var newUser = res;
          	this.data.companyUser.push(newUser);
        }

         //this.emailFormControl.value = null;
         this.emailInput.nativeElement.value = "";
      },
      (error) => {
       
        if(error.error && error.error.violations){
          alert(error.error.violations[0].message);
        }
        else
        {
          alert(error.statusText);
        }

      });

   }

   fctAddEmployeeByMail(){

    // Process checkout data here
    //console.log('Your order has been submitted', customerData);

     // stop here if form is invalid
    if (this.emailFormControl.status == 'INVALID') {
      return;
    }

    var result = this.emailFormControl.value;

    this.authService.addEmployeeCompany(result).subscribe(res => {
      alert('Invitation envoyé'); 
       //this.emailFormControl.value = null;
       this.emailInput.nativeElement.value = "";
       this.dialogRef.close(result);
    },
    (error) => {
     
      if(error.error && error.error.violations){
        alert(error.error.violations[0].message);
      }
      else
      {
        alert(error.statusText);
      }

    });

 }


  fctAdMember(userInfo){
    if(this.addingMember){return false;}
	this.addingMember = true;
    this.authService.addUserCompany(this.data.idcompany, userInfo.id, null).subscribe(
    	res => {
       		//console.log('retour ajout membre '+JSON.stringify(res['user']));
	        if(res){
	        	var newUser = res;
		        //this.userStatus[res['user']['id']] = res['status'];
		        this.data.companyUser.push(newUser);
		        for (var i = 0; i < this.contacts.length; i++) {
		    		for (let index = 0; index < this.data.companyUser.length; index++) {
		    			if(this.contacts[i]['id'] == this.data.companyUser[index]['user']['id']){
		    				this.contacts[i]['isMember'] = true;
				 		}
			 		}
		    	}
		        this.searchContact = "";
	        }
	       	this.addingMember = false;
	    },
	    (error) => {
	      this.addingMember = false;
	      if(error.error && error.error.violations){
	        alert(error.error.violations[0].message);
	      }
	      else
	      {
	        alert(error.statusText);
	      }
    	}
    );
  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

